<!--
 * @Author: lbh
 * @Date: 2023-08-02 09:52:59
 * @LastEditors: lbh
 * @LastEditTime: 2024-04-16 14:59:43
 * @Description: file content
-->
<template>
  <div class="banner-swiper px-banner-swiper">

    <!-- 編輯模式下 -->
    <div
      v-if="isEdit"
      class="refreshModel"
    >
      <el-tooltip
        class="item"
        effect="dark"
        content="刷新輪播圖"
        placement="right"
      >
        <el-button
          type="success"
          icon="el-icon-refresh"
          @click="updateSwiper"
          circle
        ></el-button>
      </el-tooltip>
      <span style="margin-left:10px">編輯模式下,將取消自動輪播</span>
    </div>
    <div
      ref="swiper-container"
      class="swiper-container"
      :class="{autoplay:configs.autoplay}"
    >
      <div class="swiper-wrapper">
        <!-- Swiper的内容 -->
        <div
          v-for="(item,index) in configs.item"
          :key="index"
          class="swiper-slide"
          :class="item.position"
          @click="clickItem('swiper-slide',index)"
        >
          <div
            class="bg swiper-lazy"
            :data-tab-color="item.tabColor"
            :data-background="isPc ? item.bg : item.bgYD || item.bg"
          ></div>
          <div class="swiper_body">
            <div class="left">
              <div
                class="title"
                :style="`font-size:${$util.window.check(item.titleFontSize,item.pcTitleFontSize,item.padTitleFontSize)} !important;`"
              >
                <span
                  v-html="$util.rex.getHtml(item[`${nowL}title`] || item.title)"
                  :style="{color:item.titleColor}"
                ></span>
              </div>
              <div
                class="desc"
                :style="`font-size:${$util.window.check(item.descFontSize,item.pcDescFontSize,item.padDescFontSize)} !important;`"
              >
                <span
                  v-html="$util.rex.getHtml(item[`${nowL}desc`] || item.desc)"
                  :style="{color:item.descColor}"
                ></span>
              </div>

              <div class="btns">
                <el-button
                  v-for="(btn,bindex) in item.btns"
                  :key="bindex"
                  type="primary"
                  :plain="btn.style == 'plain'"
                  :style="`--btnThemeColor:${btn.color||'var(--themeColor)'};--btnThemeColor5:${btn.color5||'var(--themeColor5)'};--btnThemeColorHover:${btn.colorHover|| 'var(--themeColorHover)'};--btnTextColor:${(btn.style=='plain'?btn.color:btn.textColor) || '#FFF'};`"
                  :data-click="`${btn.go}`"
                >{{btn[`${nowL}title`] || btn.title}}</el-button>
              </div>
            </div>
            <div class="right">
              <!-- <progressiveImage
                class="img"
                :src="isPc ? item.img.url : item.img.urlYD || item.img.url"
              /> -->
              <!-- <img
                class="img"
                :src="$util.image.setOss(isPc ? item.img.url : item.img.urlYD || item.img.url,350)"
                :alt="item.img.alt"
              /> -->
              <img
                class="img swiper-lazy"
                :data-src="isPc ? item.img.url : item.img.urlYD || item.img.url"
                :alt="item.img.alt"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        ref="swiper-pagination"
        class="swiper-pagination"
      ></div>
    </div>
  </div>
</template>

<script>
import langMixin from '../../mixins/lang';

// Import Swiper Vue.js components
import Swiper from 'swiper';
// Import Swiper styles
import 'swiper/css/swiper.css';

import { TweenMax } from "gsap";


export default {
  name: "banner-swiper",
  mixins: [langMixin],
  props: {
    configs: {
      default () {
        return {
          autoplay: false, // 是否自動輪播
          interval: 3, // 時間
          loop: false, // 是否 循環
          effect: "fade",
          item: [
            {
              // 標題
              title: 'RicePOS支持不同設備雲同步',
              // 描述
              desc: '移動式多功能餐飲管理系統，搭建優良高效管理系統。 輕鬆管理所有方便快捷點餐服務，減低落錯單機會。 數據可視化，直觀看到數據變化趨勢。',
              // 展示類型 left:文字在左, right:文字在右
              position: 'left',
              // 按鈕組
              btns: [
                {
                  //風格, 線性 plain 或是 實體 default
                  style: 'default',
                  title: '預約示範',
                },
                {
                  //風格, 線性 plain 或是 實體 default
                  style: 'plain',
                  title: '查看詳情',
                },
              ],
              // 圖片
              img: {
                url: require('../../assets/demo/bannarSwiper/bs_img.png'),
                alt: '',
              },
              // 背景
              bg: require('../../assets/demo/bannarSwiper/bs_bg.png'),
            },
          ],
        }
      }
    },
    Xindex: {
      default () {
        return '';
      },
    },
  },
  data () {
    return {
      mySwiper: null
    }
  },
  watch: {
    "configs.item.length" () {
      this.updateSwiper();
    },
    "configs.autoplay" () {
      this.updateSwiper();
    },
    "configs.interval" () {
      this.updateSwiper();
    },
    "configs.loop" () {
      this.updateSwiper();
    },
    "configs.effect" () {
      this.updateSwiper();
    },
    "configs" () {
      this.updateSwiper();
    }
  },
  computed: {
    isEdit () {
      return this.$store.getters.getIsEdit;
    },
    isPc () {
      return window.innerWidth > 768;
    }
  },
  mounted () {
    this.$changeLang.set("bannerSwiperChange", () => {
      this.updateSwiper();
    })
    this.initSwiper();
  },
  methods: {
    // 初始化 swiper
    initSwiper () {
      let configs = this.configs;
      const vm = this;
      let mySwiper = new Swiper(this.$refs['swiper-container'], {
        lazy: {
          loadPrevNext: true,
        },
        slidesPerView: 1,
        spaceBetween: 0,
        loop: true,//configs.loop,
        speed: 1000,
        effect: configs.effect,
        autoplay: this.isEdit ? false : configs.autoplay ? {
          delay: configs.interval * 1000,
          disableOnInteraction: false
        } : false,
        pagination: {
          el: this.$refs['swiper-pagination'],
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '"><span class="b"></span></span>';
          },
        },
        on: {
          click: (e) => {
            // * 拿到當前點擊的節點
            let srcElement = e.srcElement;
            let cl_ = srcElement.dataset.click;
            // * 判斷自己是不是 按鈕
            if (srcElement.nodeName == 'BUTTON' && cl_) {
              this.$util.route.go(cl_)
            } else {
              // * 判斷是不是點到了 span
              if (srcElement.nodeName == 'SPAN' && srcElement.parentElement.nodeName == 'BUTTON') {
                let pSrc = srcElement.parentElement;
                cl_ = pSrc.dataset.click;
                if (cl_) {
                  this.$util.route.go(cl_)
                }
              }
            }
          },
          slideChangeTransitionStart: () => {

            let container = vm.$refs['swiper-container']

            let tabColor = container.querySelector('.swiper-slide-active .bg').dataset.tabColor
            console.log(tabColor);
            if (!tabColor) tabColor = '#666666'
            if (document.querySelector('.header-box'))
              document.querySelector('.header-box').style.cssText = `--menuColor:${tabColor}`;

            TweenMax.to(".swiper-pagination-bullet-active .b", { width: 0, duration: 0 });
            TweenMax.from(".swiper-slide-active .title", {
              opacity: 0,
              y: -500,
              duration: 1,
              immediateRender: true
            })
            TweenMax.from(container.querySelector('.swiper-slide-active .desc'), {
              opacity: 0,
              y: 500,
              duration: 1,
              immediateRender: true
            })
            TweenMax.from(container.querySelector('.swiper-slide-active .btns'), {
              opacity: 0,
              y: 500,
              duration: 1,
              immediateRender: true
            })
            TweenMax.from(container.querySelector('.swiper-slide.right.swiper-slide-active .right .img'), {
              opacity: 0,
              x: -500,
              duration: 1,
              immediateRender: true
            })
            TweenMax.from(container.querySelector(".swiper-slide.left.swiper-slide-active .right .img"), {
              opacity: 0,
              x: 500,
              duration: 1,
              immediateRender: true
            })
          },
          transitionEnd: function () {
            try {
              TweenMax.to(vm.$refs['swiper-container'].querySelector(".swiper-pagination-bullet-active .b"), { width: '100%', duration: configs.interval });
            } catch (error) {

            }
          },
          init: function (swiper) {
            // 等頁面加載完成
            vm.$nextTick(() => {
              this.emit('transitionEnd');
            })
          }
        },
      });

      this.mySwiper = mySwiper;
      // * 當不是編輯, 啟動 鼠標移入 暫停輪播, 移走 重啟輪播
      if (!this.isEdit) {
        this.$refs['swiper-container'].onmouseover = function () {
          mySwiper.autoplay.stop();
        };

        this.$refs['swiper-container'].onmouseout = function () {
          mySwiper.autoplay.start();
        };
      }

    },
    // 銷毀 swiper
    destroySwiper () {
      this.mySwiper.destroy();
    },
    clickItem (e, x) {
      if (this.isEdit) {
        this.$emit('clickItem', e, x);
      }
    },
    updateSwiper () {
      this.mySwiper.destroy();
      setTimeout(() => {
        this.initSwiper();
      }, 300);
    }
  }
}
</script>

<style lang="less" scoped>
.banner-swiper {
  position: relative;
  .refreshModel {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 10;
  }
}

@media screen and (min-width: 768px) {
  .banner-swiper {
    height: 800px;

    .swiper-container {
      height: 100%;
      .swiper-slide {
        height: 100%;
        position: relative;
        &.right {
          .swiper_body {
            flex-direction: row-reverse;
          }
        }
        .bg {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          position: absolute;
        }
        .swiper_body {
          height: calc(100% - 330px);
          position: absolute;
          display: flex;
          justify-content: space-between;
          padding: 140px 110px 190px;
          .left {
            width: calc(50% - 95px);
            display: flex;
            flex-direction: column;
            .title {
              font-size: 54px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
              line-height: 65px;
            }
            .desc {
              font-size: 26px;
              // font-family: Roboto-Regular, Roboto;
              font-family: "Microsoft Yahei";
              font-weight: 200;
              color: #333333;
              margin-top: 26px;
              line-height: 40px;
            }
            .btns {
              margin-top: auto;
              margin-bottom: 30px;
              --btnThemeColor: var(--themeColor);
              --btnThemeColor5: var(--themeColor5);
              --btnThemeColorHover: var(--themeColorHover);
              --btnTextColor: #fff;
              & /deep/ .el-button {
                cursor: pointer;
                padding: 12px 38px;
                color: var(--btnTextColor);
                &.is-plain {
                  color: var(--btnThemeColor);
                  background: transparent;
                  border-color: var(--btnThemeColor5);
                  &:hover {
                    background: transparent;
                  }
                }
                background: var(--btnThemeColor);
                border-color: var(--btnThemeColor);
                &:hover {
                  background: var(--btnThemeColorHover);
                }
              }
            }
          }
          .right {
            width: calc(50% - 85px);
            .img {
              width: 100%;
              object-fit: contain;
              max-height: 100%;
            }
          }
        }
      }
      &.autoplay {
        .swiper-pagination {
          --swiper-theme-color: rgb(173, 176, 179);
          & /deep/ .swiper-pagination-bullet {
            position: relative;
            &.swiper-pagination-bullet-active {
              background-color: #dddddd; // rgba(255, 255, 255, 0.8);
              .b {
                position: absolute;
                content: "";
                width: 0;
                left: 0;
                top: 0;
                bottom: 0;
                border-radius: 15px;
                // animation: aaa 5s linear forwards;
                background-color: var(--themeColor);
              }
            }
          }
        }
      }
      .swiper-pagination {
        --swiper-theme-color: var(--themeColor);
        bottom: 140px;
        & /deep/ .swiper-pagination-bullet {
          height: 8px;
          width: 30px;
          border-radius: 15px;
          transition: 0.3s;
          background-color: #dddddd; //rgba(255, 255, 255, 0.8);
          opacity: 1;
          &:hover {
            height: 30px;
            width: 30px;
          }
          &.swiper-pagination-bullet-active {
            background-color: var(--themeColor);
            width: 90px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .px-banner-swiper {
    // height: calc(100vw / 1.26);
    .swiper-container {
      height: 100%;
      .swiper-slide {
        height: auto;
        position: relative;
        .bg {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          position: absolute;
        }
        .swiper_body {
          height: 100%;
          padding: 24px 42px 16px;
          .left {
            display: flex;
            flex-direction: column;
            .title {
              font-size: 22px;
              font-family: PingFang SC-Semibold, PingFang SC;
              font-weight: 600;
              color: #000000;
              text-align: center;
            }
            .desc {
              font-size: 12px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              margin-top: 4px;
              text-align: center;
            }
            .btns {
              margin-top: 6px;
              margin-bottom: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              button {
                cursor: pointer;
                padding: 8px 20px;
                font-size: 10px;
                span {
                  font-size: 10px;
                }
                &.is-plain {
                  color: var(--btnThemeColor, --themeColor);
                  background: transparent;
                  border-color: var(--btnThemeColor5, --themeColor5);
                  &:hover {
                    background: transparent;
                  }
                }
                background: var(--btnThemeColor, --themeColor);
                border-color: var(--btnThemeColor, --themeColor);
                &:hover {
                  background: var(--btnThemeColorHover, --themeColorHover);
                }
              }
            }
          }
          .right {
            width: 100%;
            .img {
              width: 100%;
              object-fit: cover;
              max-height: 100%;
            }
          }
        }
      }
      .swiper-pagination {
        --swiper-theme-color: var(--themeColor);
        display: none;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .px-banner-swiper {
    .swiper-container {
      .swiper-slide {
        .swiper_body {
          .left {
            .title {
              font-size: 22px !important;
            }
            .desc {
              font-size: 12px !important;
            }
            .btns {
              button {
                padding: 5px 14px !important;
                font-size: 9px !important;
                span {
                  font-size: 9px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

